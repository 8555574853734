/* Typography */

body {
  font-size: 14px;
  font-family: $type1;
  font-weight: initial;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $type1;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}

p,
a:not([href]):not([tabindex]) {
  color: $body-color;
  font-size: $default-font-size;
}

.h1,
h1 {
  font-size: 2.3125rem; //37px
}

.h2,
h2 {
  font-size: 2rem; //32px
}

.h3,
h3 {
  font-size: 1.6875rem; //27px
}

.h4,
h4 {
  font-size: 1.25rem; //20px
}

.h5,
h5 {
  font-size: 1.0625rem; //17px
}

.h6,
h6 {
  font-size: 1rem; //16px
}

p {
  font-size: $default-font-size;
}

.display-1 {
  font-size: 3.75rem;
  @media (max-width: 991px) {
    font-size: 3rem;
  }
}
.display-2 {
  font-size: 3.125rem;
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
}
.display-3 {
  font-size: 2.5rem;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
}
.display-4 {
  font-size: 1.875rem;
  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}
.display-5 {
  font-size: 1.25rem;
  @media (max-width: 991px) {
    font-size: 1rem;
  }
}
.blockquote {
  padding: 1.25rem;
  border: 1px solid $border-color;
}

address {
  p {
    margin-bottom: 0;
  }
}

//blockqoute color variations
@each $color, $value in $theme-colors {
  .blockquote-#{$color} {
    @include blockquote($value);
  }
}
.error-page {
  h1 {
    font-size: 12rem;
    @media (max-width: 991px) {
      font-size: 8rem;
    }
  }
}
.icon-lg {
  font-size: 2.5rem;
}
.icon-md {
  font-size: 1.875rem;
}
.icon-sm {
  font-size: 1rem;
}
