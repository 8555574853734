/* Layouts */

// Sidebar Mini
.sidebar-mini {
  @media (min-width: 992px) {
    .page-body-wrapper {
      width: calc(100% - #{$sidebar-width-mini});
      .navbar {
        &.navbar-mini {
          &.fixed-top {
            left: $sidebar-width-mini;
          }
        }
      }
    }
    .sidebar {
      width: $sidebar-width-mini;
      .nav {
        .sidebar-category {
          text-align: center;
        }
        .nav-item {
          padding: 0;
          .nav-link {
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
            @include flex-direction(column);
            text-align: center;
            position: relative;
            border-bottom: none;
            .menu-title {
              display: block;
              margin: auto;
            }
            .badge {
              margin-left: 5px;
              display: none;
            }
            i {
              &.menu-icon {
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 0.625rem;
              }
              &.menu-arrow {
                display: inline-block;
                margin-left: 5px;
                position: absolute;
                top: 50%;
                right: 20px;
                @include transform(translateY(-50%));
              }
            }
          }
        }
      }
    }

    &:not(.sidebar-icon-only) {
      //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
      .navbar {
        left: $sidebar-width-mini;
        .navbar-menu-wrapper {
          width: calc(100% - #{$sidebar-width-mini});
        }
      }
      .sidebar {
        .nav {
          &.sub-menu {
            padding: 0;
            border-top: none;
            .nav-item {
              &::before {
                left: 1.562rem;
              }
              .nav-link {
                padding: 0.75rem 0 0.75rem 0.5rem;
                margin-left: auto;
                margin-right: auto;
                display: block;
                text-align: left;
                width: 66%;
              }
            }
          }
        }
      }
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      left: $sidebar-width-icon;
      .rtl & {
        left: 0;
        right: 150px;
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .sidebar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          padding: 0;
          .nav-link {
            display: block;
            text-align: center;
            padding: 16px 16px;
            .menu-title,
            .badge,
            .menu-sub-title {
              display: none;
            }
            .menu-title {
              @include border-radius(0 5px 5px 0px);
              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 5px);
              }
            }
            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
            &[aria-expanded] {
              .menu-title {
                @include border-radius(0 5px 0 0px);
                @at-root #{selector-append(".rtl", &)} {
                  @include border-radius(5px 0 0 0);
                }
              }
            }
          }
          &.nav-profile {
            display: none;
          }
          &.nav-category {
            display: none;
          }
          &.nav-doc {
            margin: 0;
            i {
              display: block;
            }
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                @include display-flex;
                @include align-items(center);
                background: $sidebar-light-menu-hover-bg;
                @at-root #{selector-append(".sidebar-dark", &)} {
                  background: $sidebar-dark-menu-active-bg;
                }
                padding: 0.5rem 1.4rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                bottom: 0;
                width: $icon-only-collapse-width;
                z-index: 1;
                line-height: 1.8;
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: left;
                }
                @at-root #{selector-append(".sidebar-dark", &)} {
                  color: $white;
                }
                &:after {
                  display: none;
                }
              }
            }
            .mr-6 {
    margin-right: 0.5rem !important;
}
            .collapse,
            .collapsing {
              display: block;
              padding: 0.5rem 0;
              background: $sidebar-light-menu-hover-bg;
              @include border-radius(0 0 5px 0);
              @at-root #{selector-append(".sidebar-dark", &)} {
                background: $sidebar-dark-bg;
              }
              position: absolute;
              left: $sidebar-width-icon;
              width: $icon-only-collapse-width;
              @at-root #{selector-append(".rtl", &)} {
                left: auto;
                right: $sidebar-width-icon;
                @include border-radius(0 0 0 5px);
              }
            }
          }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            padding: 0 1.25rem 0 1.25rem;
            .nav-link {
              text-align: left;
              padding-left: 20px;
            }
          }
          @at-root #{selector-append(".rtl", &)} {
            .nav-item {
              margin-right: auto;
              margin-left: 0;
              .nav-link {
                text-align: right;
                &:before {
                  left: 0;
                  right: unset;
                }
              }
            }
          }
        }
      }
      .sidebar-actions {
        display: none;
      }
    }
    .page-body-wrapper {
      transition: all $action-transition-duration $action-transition-timing-function;
      -webkit-transition: all $action-transition-duration $action-transition-timing-function;
      -moz-transition: all $action-transition-duration $action-transition-timing-function;
      -ms-transition: all $action-transition-duration $action-transition-timing-function;
    }
    .main-panel {
      width: 100%;
    }
  }
}

// Hidden Sidebar
.sidebar-hidden {
  @media (min-width: 992px) {
    .navbar {
      left: 0;
      .navbar-menu-wrapper {
        width: 100%;
      }
    }
    .sidebar {
      width: 0;
      .sidebar-brand-wrapper {
        width: $sidebar-width-icon;
      }
    }
    .page-body-wrapper {
      width: 100%;
    }
    .main-panel {
      width: 100%;
    }
  }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
  @media (min-width: 992px) {
    .page-body-wrapper {
      position: relative;
      width: 100%;
      .sidebar {
        transition: none;
        height: 100%;
      }
    }

    &:not(.sidebar-hidden) {
      .sidebar {
        position: absolute;
      }
      .page-body-wrapper {
        width: 100%;
      }
    }

    .main-panel {
      width: 100%;
      transition: none;
    }
  }
}

//Fixed sidebar
.sidebar-fixed {
  @media (min-width: 992px) {
    .sidebar {
      position: fixed;
      max-height: auto;

      .nav {
        max-height: calc(100vh - #{$navbar-height});
        overflow: auto;
        position: relative;

        &.sub-menu {
          max-height: none;
        }
      }
    }

    .page-body-wrapper {
      width: 100%;
      margin-left: $sidebar-width-lg;
    }

    &.sidebar-icon-only {
      .page-body-wrapper {
        margin-left: $sidebar-width-icon;
      }
    }
  }
}

//Boxed layout
.boxed-layout {
  @media (min-width: 992px) {
    .container-scroller {
      background: $boxed-layout-bg;
      padding: 0 calc((100% - #{$boxed-container-width}) / 2);
    }
    .navbar {
      &.fixed-top {
        @media (min-width: 1200px) {
          margin: 0 calc((100% - #{$boxed-container-width}) / 2);
          max-width: $boxed-container-width;
        }
      }
    }
  }
}

//RTL layout
.rtl {
  direction: rtl;
  text-align: right;

  .sidebar {
    .nav {
      padding-right: 0;
      .nav-item {
        .nav-link {
          text-align: middle;
        }
      }
    }
  }
  .page-body-wrapper {
    transition: all $action-transition-duration $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration $action-transition-timing-function;
    -moz-transition: all $action-transition-duration $action-transition-timing-function;
    -ms-transition: all $action-transition-duration $action-transition-timing-function;
  }
  .navbar {
    transition: all $action-transition-duration $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration $action-transition-timing-function;
    -moz-transition: all $action-transition-duration $action-transition-timing-function;
    -ms-transition: all $action-transition-duration $action-transition-timing-function;
    right: $sidebar-width-lg;
    @media (max-width:900px) {
      right: 0;
    }
  }
  .product-chart-wrapper,
  .settings-panel .tab-content .tab-pane .scroll-wrapper,
  .sidebar-fixed .nav,
  .table-responsive,
  ul.chats {
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
  &.sidebar-icon-only {
    .navbar {
    right: $sidebar-width-icon;
    left: 0;
    }
  }
}
.mycard{
 min-height:440px; 

}
.modal-content {
  background-color:#ffffff !important;

}
.modal-open .modal{
  
  overflow-y: hidden !important;
}

.modal-open .modal::-webkit-scrollbar {
  display: none;
}

.MuiPaper-root{
  overflow:auto;  
  
  }
  .MUIDataTableHeadCell-data-71{
   
  width:110px;
  }
  .inline {
    display: inline-block;
    font-size: 12px;
    max-width: 100px;
    color:red;
  } 
  .MuiChip-label {
    overflow:none !important;
    line-height:2;
  }
  .MuiTypography-h6 {
    font-weight:inherit !important;
    letter-spacing: inherit !important;
  }
  .rct-scroll{
    position: relative;
       overflow: hidden;
       width: 100%;
       height: auto;
       min-height: 100px;
       max-height: 440px; 
   
   }
   .list-unstyled {
       padding-left: 0;
       list-style: none;
   }
   .pl-3, .px-3 {
       padding-left: 1rem !important;
   }
   .activity-widget li span.badge {
       position: absolute;
       right: auto;
       top: 0;
       left: -9.75px;
       min-height: 1.125rem;
       min-width: 1.125rem;
       font-size: 0;
   }
   .badge:not(.badge-xs) {
       padding: 5px 15px 4px;
       color: #fff;
   }
   
   
   .p-0, .square-10, .square-15, .square-20, .square-30, .square-40, .size-40, .square-50, .square-60, .size-60, .square-70, .square-80, .size-80, .square-100, .size-100, .square-120, .size-120 {
       padding: 0 !important;
   }
   
   
   .activity-widget li span.activity-time {
       display: block;
       font-size: 12px;
   }
   .font-xs {
       font-size: 0.75rem !important;
       line-height: 1.25rem;
   }
   .inline {
     display: inline-block;
     font-size: 12px;
     max-width: 100px;
     color:red;
   } 
   .activity-widget li {
       border-left: 1px solid #ebedf2;
       padding: 0 20px 15px;
       position: relative;
   }
   .rct-block .rct-block-content {
       padding: 1.25rem;
   }
   .activity-widget li span.badge:before {
       border: 3px solid #fff;
       position: absolute;
       content: "";
       top: 0;
       bottom: 0;
       left: 0;
       right: 0;
       border-radius: 100%;
   }
.mr-6{
      margin-right: 2.5rem !important;
}
.txtrgt {text-align:right;}
.btnpd {padding-top:0px;}
.gridalgn {text-align:center;margin-top:10px;}
.bxdesk {width:8.96667%;}
@media (max-width: 480px) {
  .mr-6{
      margin-right: -26px !important;
}
.txtrgt {text-align:left !important;}
.btnpd {padding-top:10px;}
.bxdesk {width:100%;}
}
.react-datepicker-popper { z-index: 999 !important }
.MuiSlider-marked {
    margin-bottom: 20px !important;
}

.zeromarginpadding {
    margin: 0px !important;
    padding: 0px !important;
}

.margin4padding {
    margin: 4px !important;
    padding: 0px !important;
}
.margin7padding {
    margin-top: 34px !important;
    padding: 0px !important;
    margin-left: 7px;
    margin-right: 21px;
}
.margin15padding {
    margin: 15px !important;
    padding: 0px !important;
}
.pfont {
  font-size:13px !important;

}
.pfont-center {
  font-size:13px !important;
  text-align: center;
  height: 42px;
}
.form-width{
  width:90% !important;
  margin-right:5px;
  display:inline !important;
}
.blueclass{
  color:blue;
}
.jqx-input1 {
  left:38px;
  top: 124px;
  position: absolute;
  background: transparent;
  border: none;
}

.jqximg1 {
    left: 60px;
    top: 102px;
    position: absolute;
    z-index: 99999;
    width: 25% !important;
}
.jqx-input {
  left: 116px;
  top: 205px;
  position: absolute;
  background: transparent;
  border: none;
}

.jqximg {
    left: 121px;
    top: 135px;
    position: absolute;
    z-index: 99999;
    
}
.toottipcls{
  font-size: 16px !important;
}

.deliverybox {
background-color: #5d92f4;
color: #ffffff;
}

.averageinbox {
  background-color: #00d0bd;
  color: #ffffff;
}
.percent-text{
display: none;
}
.ppercent-text{
 font-weight:500;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
 
.MUIDataTableHeadCell-fixedHeader {
  
top: 0px;
z-index: 100;
position: sticky;


}

.react-confirm-alert-body{
padding:23px !important;
}
.rdt_TableCol{

background-color: #F4F7FA !important;

}
.senderprofileicon
{
cursor: pointer !important;
color:#127ced !important;
text-align: center;
margin-right: 3px;

}
.h1lineheight {
  line-height:38px !important;


}
.form-control-lg {
   border-radius:0px !important;
}
.react-datepicker-wrapper {
  display: block !important;;
 
}

.testimonial-group > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.testimonial-group > .row > .col-4 {
  display: inline-grid;
}

/* Decorations */

.myzindex {
  z-index:9;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

.top215{

top:0px;

}

input {
border: 1px solid #dee2e6 !important;
}
.ck-content {
   min-height:400px;
}

.datepickerwidth {
  width: 91%;
  margin-left: 8px;
  
}

.box_pagination .pagination {
  margin-top:10px;
  }

  .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
    }

 .pagination>li {
  display: inline;
    }

.pagination > li > span:disabled, .pagination > li > span:disabled:hover, .pagination > li > span:disabled:focus, .pagination > li > button:disabled, .pagination > li > button:disabled:hover, .pagination > li > button:disabled:focus {
 color:#777;
 cursor: not-allowed;
 background-color: #fff;
 border-color: #ddd;
}  
.pagination > li > button, .pagination > li > span {
position: relative;
float: left;
padding: 6px 12px;
margin-left: -1px;
line-height: 1.42857143;
color: #337ab7;
text-decoration: none;
background-color: #fff;
border: 1px solid #ddd;
outline: 0;
  } 
  .pagination > li > button.active, .pagination > li > span.active, .pagination > li > button.active:hover, .pagination > li > span.active:hover, .pagination > li > button.active:focus, .pagination > li > span.active:focus {
    z-index: 3;
    color:#fff;
    cursor:default;
    background-color:#337ab7;
    border-color:#337ab7;
    }

.paginationselect {
  display: inline-block;
  width: 68px;
  margin-left: 10px;
  margin-top: 10px;
  height: 30px;
}

.MuiBox-root
{
padding:0px !important;
}

.marginTop60 {
margin-top:60px !important;
}


